
import { defineComponent, ref, onMounted } from "vue";
import { CreateOrEditCustomerDto } from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import useCustomers from "@/composables/useCustomers";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import CustomerForm from "@/components/Forms/CustomerForm.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  components: {
    CustomerForm,
  },
  setup() {
    const defaultCustomer = ref({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      outletName: "",
      outletAddress: "",
      landmark: "",
      retailChannel: "",
      region: "",
      state: "",
      lga: "",
      territoryId: "",
      urno: "",
      rcS8Number: "",
      priceListId: "",
    } as unknown as CreateOrEditCustomerDto);
    const hold = ref(JSON.parse(JSON.stringify(defaultCustomer.value)));

    const { updateOrCreateCustomer } = useCustomers();
    const store = useStore();

    const saving = ref(false);

    const submit = async () => {
      saving.value = true;
      await updateOrCreateCustomer(defaultCustomer.value);
      const error = store.getters.getErrors;
      saving.value = false;
      if (!error) {
        Swal.fire({
          text: "Customer created successfully",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
          },
        }).then(function () {
          defaultCustomer.value = { ...hold };
          // router.push({ name: "customers" });
        });
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Customers", ["Customers", "Create"]);
    });

    return {
      defaultCustomer,
      saving,
      submit,
    };
  },
});
